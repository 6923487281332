.grad0 {
  background: -webkit-linear-gradient(315deg, #8e160f, #d3aa4d);
  background: -o-linear-gradient(315deg, #8e160f, #d3aa4d);
  background: linear-gradient(315deg, #8e160f, #d3aa4d);
}
.grad1 {
  background: -webkit-linear-gradient(135deg, rgb(253, 190, 223), rgb(21, 127, 194));
  background: -o-linear-gradient(135deg, rgb(253, 190, 223), rgb(21, 127, 194));
  background: linear-gradient(135deg, #fdbedf, #157fc2);
}
.grad2 {
  background: -webkit-linear-gradient(180deg, rgb(201, 224, 19), rgb(156, 7, 213));
  background: -o-linear-gradient(180deg, rgb(201, 224, 19), rgb(156, 7, 213));
  background: linear-gradient(180deg, #c9e013, #9c07d5);
}
.grad3 {
  background: -webkit-linear-gradient(135deg, rgb(141, 64, 52), rgb(125, 253, 228));
  background: -o-linear-gradient(135deg, rgb(141, 64, 52), rgb(125, 253, 228));
  background: linear-gradient(135deg, #8d4034, #7dfde4);
}
.grad4 {
  background: -webkit-linear-gradient(135deg, rgb(209, 55, 139), rgb(68, 198, 243));
  background: -o-linear-gradient(135deg, rgb(209, 55, 139), rgb(68, 198, 243));
  background: linear-gradient(135deg, #d1378b, #44c6f3);
}
.grad5 {
  background: -webkit-linear-gradient(180deg, rgb(199, 154, 132), rgb(68, 134, 141));
  background: -o-linear-gradient(180deg, rgb(199, 154, 132), rgb(68, 134, 141));
  background: linear-gradient(180deg, #c79a84, #44868d);
}
.grad6 {
  background: -webkit-linear-gradient(32deg, rgb(74, 218, 233), rgb(226, 204, 175));
  background: -o-linear-gradient(32deg, rgb(74, 218, 233), rgb(226, 204, 175));
  background: linear-gradient(32deg, #4adae9, #e2ccaf);
}
.grad7 {
  background: -webkit-linear-gradient(180deg, rgb(7, 220, 237), rgb(145, 39, 22));
  background: -o-linear-gradient(180deg, rgb(7, 220, 237), rgb(145, 39, 22));
  background: linear-gradient(180deg, #07dced, #912716);
}
.grad8 {
  background: -webkit-linear-gradient(180deg, rgb(220, 132, 146), rgb(10, 126, 110));
  background: -o-linear-gradient(180deg, rgb(220, 132, 146), rgb(10, 126, 110));
  background: linear-gradient(180deg, #dc8492, #0a7e6e);
}
.grad9 {
  background: -webkit-linear-gradient(225deg, rgb(231, 160, 156), rgb(100, 168, 96));
  background: -o-linear-gradient(225deg, rgb(231, 160, 156), rgb(100, 168, 96));
  background: linear-gradient(225deg, #e7a09c, #64a860);
}
.grad10 {
  background: -webkit-linear-gradient(135deg, rgb(25, 1, 177), rgb(241, 61, 193));
  background: -o-linear-gradient(135deg, rgb(25, 1, 177), rgb(241, 61, 193));
  background: linear-gradient(135deg, #1901b1, #f13dc1);
}
.grad11 {
  background: -webkit-linear-gradient(135deg, rgb(130, 108, 131), rgb(240, 248, 185));
  background: -o-linear-gradient(135deg, rgb(130, 108, 131), rgb(240, 248, 185));
  background: linear-gradient(135deg, #826c83, #f0f8b9);
}
.grad12 {
  background: -webkit-linear-gradient(135deg, rgb(26, 198, 250), rgb(189, 142, 151));
  background: -o-linear-gradient(135deg, rgb(26, 198, 250), rgb(189, 142, 151));
  background: linear-gradient(135deg, #1ac6fa, #bd8e97);
}
.grad13 {
  background: -webkit-linear-gradient(135deg, rgb(158, 2, 159), rgb(152, 140, 24));
  background: -o-linear-gradient(135deg, rgb(158, 2, 159), rgb(152, 140, 24));
  background: linear-gradient(135deg, #9e029f, #988c18);
}

.gradFill0 svg * {
  fill: -webkit-linear-gradient(315deg, #8e160f, #d3aa4d);
  fill: -o-linear-gradient(315deg, #8e160f, #d3aa4d);
  fill: linear-gradient(315deg, #8e160f, #d3aa4d);
}
.gradFill1 svg * {
  fill: -webkit-linear-gradient(135deg, rgb(253, 190, 223), rgb(21, 127, 194));
  fill: -o-linear-gradient(135deg, rgb(253, 190, 223), rgb(21, 127, 194));
  fill: linear-gradient(135deg, #fdbedf, #157fc2);
}
.gradFill2 svg * {
  fill: -webkit-linear-gradient(180deg, rgb(201, 224, 19), rgb(156, 7, 213));
  fill: -o-linear-gradient(180deg, rgb(201, 224, 19), rgb(156, 7, 213));
  fill: linear-gradient(180deg, #c9e013, #9c07d5);
}
.gradFill3 svg * {
  fill: -webkit-linear-gradient(135deg, rgb(141, 64, 52), rgb(125, 253, 228));
  fill: -o-linear-gradient(135deg, rgb(141, 64, 52), rgb(125, 253, 228));
  fill: linear-gradient(135deg, #8d4034, #7dfde4);
}
.gradFill4 svg * {
  fill: -webkit-linear-gradient(135deg, rgb(209, 55, 139), rgb(68, 198, 243));
  fill: -o-linear-gradient(135deg, rgb(209, 55, 139), rgb(68, 198, 243));
  fill: linear-gradient(135deg, #d1378b, #44c6f3);
}
.gradFill5 svg * {
  fill: -webkit-linear-gradient(180deg, rgb(199, 154, 132), rgb(68, 134, 141));
  fill: -o-linear-gradient(180deg, rgb(199, 154, 132), rgb(68, 134, 141));
  fill: linear-gradient(180deg, #c79a84, #44868d);
}
.gradFill6 svg * {
  fill: -webkit-linear-gradient(32deg, rgb(74, 218, 233), rgb(226, 204, 175));
  fill: -o-linear-gradient(32deg, rgb(74, 218, 233), rgb(226, 204, 175));
  fill: linear-gradient(32deg, #4adae9, #e2ccaf);
}
.gradFill7 svg * {
  fill: -webkit-linear-gradient(180deg, rgb(7, 220, 237), rgb(145, 39, 22));
  fill: -o-linear-gradient(180deg, rgb(7, 220, 237), rgb(145, 39, 22));
  fill: linear-gradient(180deg, #07dced, #912716);
}
.gradFill8 svg * {
  fill: -webkit-linear-gradient(180deg, rgb(220, 132, 146), rgb(10, 126, 110));
  fill: -o-linear-gradient(180deg, rgb(220, 132, 146), rgb(10, 126, 110));
  fill: linear-gradient(180deg, #dc8492, #0a7e6e);
}
.gradFill9 svg * {
  fill: -webkit-linear-gradient(225deg, rgb(231, 160, 156), rgb(100, 168, 96));
  fill: -o-linear-gradient(225deg, rgb(231, 160, 156), rgb(100, 168, 96));
  fill: linear-gradient(225deg, #e7a09c, #64a860);
}
.gradFill10 svg * {
  fill: -webkit-linear-gradient(135deg, rgb(25, 1, 177), rgb(241, 61, 193));
  fill: -o-linear-gradient(135deg, rgb(25, 1, 177), rgb(241, 61, 193));
  fill: linear-gradient(135deg, #1901b1, #f13dc1);
}
.gradFill11 svg * {
  fill: -webkit-linear-gradient(135deg, rgb(130, 108, 131), rgb(240, 248, 185));
  fill: -o-linear-gradient(135deg, rgb(130, 108, 131), rgb(240, 248, 185));
  fill: linear-gradient(135deg, #826c83, #f0f8b9);
}
.gradFill12 svg * {
  fill: -webkit-linear-gradient(135deg, rgb(26, 198, 250), rgb(189, 142, 151));
  fill: -o-linear-gradient(135deg, rgb(26, 198, 250), rgb(189, 142, 151));
  fill: linear-gradient(135deg, #1ac6fa, #bd8e97);
}
.gradFill13 svg * {
  fill: -webkit-linear-gradient(135deg, rgb(158, 2, 159), rgb(152, 140, 24));
  fill: -o-linear-gradient(135deg, rgb(158, 2, 159), rgb(152, 140, 24));
  fill: linear-gradient(135deg, #9e029f, #988c18);
}

.gradFill0 svg {
  --color-stop-1: rgb(255,255,255);
  --color-stop-2: rgba(224, 19, 75);
}
.gradFill1 svg {
  --color-stop-1: rgb(250, 223, 107);
  --color-stop-2: rgb(174, 108, 14);
}
.gradFill2 svg {
  --color-stop-1: rgb(159, 103, 247);
  --color-stop-2: rgb(152, 0, 232);
}
.gradFill3 svg {
  --color-stop-1: rgb(159, 103, 247);
  --color-stop-2: rgb(152, 0, 232);
}
.gradFill4 svg {
  --color-stop-1: rgb(234, 56, 51);
  --color-stop-2: rgb(235, 0, 194);
}
.gradFill5 svg {
  --color-stop-1: rgb(229	,149	,128	);
  --color-stop-2: rgb(234, 56, 51);
}
.gradFill6 svg {
  --color-stop-1: rgb(231	,219,	156	);
  --color-stop-2: rgb(54	,236	,131	);
}
.gradFill7 svg {
  --color-stop-1: rgb(203	,0	,31	);
  --color-stop-2: rgb(12,	58,	153	);
}
.gradFill8 svg {
  --color-stop-1: #07dced;
  --color-stop-2: #912716;
}
// .gradFill8 svg {
//   --color-stop-1: #dc8492;
//   --color-stop-2: #0a7e6e;
// }
.gradFill9 svg {
  --color-stop-1: #e7a09c;
  --color-stop-2: #64a860;
}
.gradFill10 svg {
  --color-stop-1: #1901b1;
  --color-stop-2: #f13dc1;
}
.gradFill11 svg {
  --color-stop-1: #826c83;
  --color-stop-2: #f0f8b9;
}
.gradFill12 svg {
  --color-stop-1: #1ac6fa;
  --color-stop-2: #bd8e97;
}
.gradFill13 svg {
  --color-stop-1: #9e029f;
  --color-stop-2: #988c18;
}
