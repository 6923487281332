@import url('./assets/styles/_gradients.scss');

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0px;
  padding: 0px;
}
body {
  background: #181818;
  color: #f3f3f3;
  position: relative;
  min-height: 100vh;
  width: 100%;
}
#logo {
  position: absolute;
  bottom: 2.5vw;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 52px;
  // background:#f00;
  // mask-image: url(assets/shuniologo_w.svg);
  clip-path: url(#logoSVG);
  mask-image: url(#logoSVG);
  -webkit-background-clip: url(#logoSVG);
  background-clip: url(#logoSVG);
  // clip-path: url(assets/shuniologo_w.svg);
  svg {
    // fill:#000;
    fill: url(#gradient-horizontal) #efefef;
    transition: all 2.2s ease;
  }
  // img{
  //   object-fit: contain;
  // }
}
nav {
  position: absolute;
  bottom: calc(2.5vw + 70px);
  display: flex;
  z-index: 1010;
  left: 50%;
  transform: translateX(-50%);
  a {
    position: relative;
    display: block;
    margin: auto;
    width: 75px;
    padding: 15px 0px;
    background: rgba(255, 255, 255, 0.65);
    transition: background 0.5s ease;
    &:before,
    &:after {
      content: ' ';
      border-bottom: 2px #fff solid;
      position: absolute;
      width: 43%;
      transform-origin: center left;
      top: 65%;
    }
    &:before {
      transform: rotate(-20deg);
      transform-origin: center left;
      left: 10%;
    }
    &:after {
      transform: rotate(20deg);
      transform-origin: center right;
      right: 10%;
    }
    &#prev {
      margin-right: 5px;
    }
    &#next {
      margin-left: 5px;
      transform: scale(-1);
    }
    @media (hover: hover) {
      &:hover {
        background: rgba(255, 255, 255, 1);
        &:before,
        &:after {
          border-color: #000;
        }
      }
    }
  }
  &.last {
    #next {
      display: none;
    }
  }

  &.first {
    #prev {
      display: none;
    }
  }
}
header {
  position: relative;
  h1 {
    font-weight: 400;
    position: relative;
    padding-top: 3vw;
    text-align: center;
    // font-family: 'Burgues Script';
    font-family: 'Notable', sans-serif;
    font-size: 1.25em;
    letter-spacing: 0.18em;
    color: #fcfdcf;
  }
}
#main {
  transform-origin: top center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  z-index: 1000;
  .transition & {
    overflow-y: hidden;
  }
  h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 1em;
    line-height: 1em;
    padding-top: 13px;
  }

  section {
    position: absolute;
    width: 100vw;
    height: 100vh;
    // display: flex;
    top: 0px;
    left: 0px;
    text-align: center;
    // &.in {
    //   top: 100vh;
    // }
    div {
      transform-origin: center;
      margin: auto;
      position: absolute;
      top:50%;
      left:50%;
    }
    iframe {
      position: relative;
      margin:auto;
      background: #111;
      overflow: hidden;
      border: none;
    }
  }
}
